@import '../../../../scss/theme-bootstrap';

.product-quote {
  font-size: 14px;
  padding: 15px 20px;
  text-align: center;
  color: $color-dark-gray;
  &__headline {
    @include t7;
    margin-top: 60px;
    margin-bottom: 5px;
  }
  &__quote {
    @include t2;
    margin-bottom: 30px;
  }
  &__author {
    @include a1;
  }
  &__read-more {
    display: inline-block;
    margin-top: 5px;
  }
}
